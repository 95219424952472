import React, { useEffect, useState } from 'react';
import { 
    Toolbar, 
    SaveButton, 
    DeleteButton, 
    useDataProvider, 
    SelectInput, 
    Create, 
    Edit, 
    SimpleForm, 
    Datagrid, 
    List, 
    TextField, 
    EmailField, 
    TextInput, 
    CreateButton, 
    ReferenceInput, 
    PasswordInput, 
    minLength, 
    required, 
    email, 
    AutocompleteInput, 
    useGetIdentity,
    NumberInput,
} from 'react-admin';
import { useParams } from 'react-router-dom';
import { getReadableLocaleName } from './i18nProvider';
import EmptyUser from './user/EmptyUser';

export const useUserContext = () => {
    const { customerId, partnerId } = useParams();
    const { identity } = useGetIdentity();

    const defaultValues = {
        customer_id: customerId || (!partnerId && identity && identity.customer_id) || null,
        partner_id: partnerId || (!customerId && identity && identity.partner_id) || null,
    };

    const getFilter = () => {
        if (customerId) {
            return { customer_id: parseInt(customerId) };
        } else if (partnerId) {
            return { partner_id: parseInt(partnerId) };
        } else if (identity?.customer_id) {
            return { customer_id: identity.customer_id };
        } else if (identity?.partner_id) {
            return { partner_id: identity.partner_id };
        }
        return {};
    };

    const getCreatePath = () => {
        if (customerId) {
            return `/customers/${customerId}/users/create`;
        } else if (partnerId) {
            return `/partners/${partnerId}/users/create`;
        }
        return '/users/create';
    };

    const getEditPath = (record) => {
        if (customerId) {
            return `/customers/${customerId}/users/${record.id}`;
        } else if (partnerId) {
            return `/partners/${partnerId}/users/${record.id}`;
        }
        return `/users/${record.id}`;
    };

    const redirect = () => {
        if (customerId) {
            return `customers/${customerId}/users`;
        } else if (partnerId) {
            return `partners/${partnerId}/users`;
        }
        return 'users';
    };

    return {
        customerId,
        partnerId,
        defaultValues,
        getFilter,
        getCreatePath,
        getEditPath,
        redirect,
    };
};

const CustomToolbar = () => {
  const { redirect } = useUserContext();

  return (
    <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <SaveButton />
        <DeleteButton redirect={redirect} mutationMode="pessimistic" />
    </Toolbar>
);
}

const LocaleInput = () => {
    const dataProvider = useDataProvider();
    const [choices, setChoices] = useState([]);
    const [defaultValue, setDefaultValue] = useState('en-US');

    useEffect(() => {
        const fetchLocales = async () => {
            try {
                const { data } = await dataProvider.custom('users/locales', { method: 'GET' });
                const formattedChoices = data.map((locale: string) => ({
                    id: locale,
                    name: getReadableLocaleName(locale)
                }));
                setChoices(formattedChoices);

                if (!formattedChoices.some((choice: any) => choice.id === defaultValue)) {
                    setDefaultValue(formattedChoices[0]?.id);
                }
            } catch (error) {
                console.error('Error fetching locales:', error);
            }
        };

        fetchLocales();
    }, [dataProvider]);

    return (
        <SelectInput
            source="locale"
            choices={choices}
            label="resources.generic.locale"
            isRequired
            defaultValue={defaultValue}
        />
    );
};

export const UserList = () => {
    const { getFilter, getCreatePath, getEditPath } = useUserContext();
    
    return (
        <List 
            exporter={false} 
            resource='users' 
            empty={<EmptyUser/>}
            filter={getFilter()}
            actions={
                <CreateButton 
                    label="Create User"
                    to={getCreatePath()}
                />
            }
        >
            <Datagrid rowClick={(id, resource, record) => getEditPath(record)} bulkActionButtons={false}>
                <TextField source="id" />
                <TextField source="first_name" />
                <TextField source="last_name" />
                <EmailField source="email" />
            </Datagrid>
        </List>
    );
};

export const UserEdit = () => {
    const { redirect } = useUserContext();

    return (
        <Edit title="resources.users.edit" resource='users' redirect={redirect}>
            <SimpleForm toolbar={<CustomToolbar/>}>
                <TextInput source="first_name" validate={required()} />
                <TextInput source="last_name" validate={required()} />
                <TextInput source="email" type="email" validate={[required(), email()]} />
                <PasswordInput source="new_password" validate={[minLength(8)]} autoComplete='no' />
                <LocaleInput />
            </SimpleForm>
        </Edit>
    );
};

const equalToPassword = (value: any, allValues: any) => {
    if (value !== allValues.password) {
        return 'The two passwords must match';
    }
};

export const UserCreate = () => {
    const { defaultValues, redirect } = useUserContext();

    return (
        <Create resource='users' redirect={redirect}>
            <SimpleForm defaultValues={defaultValues}>
                <TextInput source="email" type="email"  validate={[required(), email()]} />
                <TextInput source="first_name" validate={required()} />
                <TextInput source="last_name" validate={required()} />

                {defaultValues.customer_id && (
                  <TextInput source="customer_id" sx={{display: 'none'}} />
                )}

                {defaultValues.partner_id && (
                  <TextInput source="partner_id" sx={{display: 'none'}} />
                )}

                <LocaleInput />
                <PasswordInput source="password" validate={required()} />
                <PasswordInput source="confirm_password" validate={[equalToPassword, required()]} />
            </SimpleForm>
        </Create>
    );
};