import { memo, ReactElement } from 'react';
import PropTypes from 'prop-types';
import Queue from '@mui/icons-material/Queue';
import { Link } from 'react-router-dom';
import { stringify } from 'query-string';
import { useResourceContext, useRecordContext, useCreatePath } from 'ra-core';

import { Button, ButtonProps } from 'react-admin';

export const CustomCloneButton = ({ excludeFields = [], label = 'ra.action.clone', scrollToTop = true, icon = defaultIcon, ...rest }: CustomCloneButtonProps) => {
    const resource = useResourceContext();
    const record = useRecordContext();
    const createPath = useCreatePath();
    const pathname = createPath({ resource, type: 'create' });

    const customOmitFields = (record: any) => {
        const newRecord = { ...record };
        excludeFields.forEach(field => delete newRecord[field]);
        return newRecord;
    };

    return (
        <Button
            component={Link}
            to={
                record
                    ? {
                          pathname,
                          search: stringify({
                              source: JSON.stringify(customOmitFields(record)),
                          }),
                      }
                    : pathname
            }
            state={{ _scrollToTop: scrollToTop }}
            label={label}
            onClick={stopPropagation}
            {...sanitizeRestProps(rest)}
        >
            {icon}
        </Button>
    );
};

const defaultIcon = <Queue />;
const stopPropagation = e => e.stopPropagation();

const sanitizeRestProps = ({
    resource,
    record,
    ...rest
}: Omit<CustomCloneButtonProps, 'label' | 'scrollToTop' | 'icon' | 'excludeFields'>) => rest;

interface CustomCloneButtonProps extends Omit<ButtonProps<typeof Link>, 'to'> {
    record?: any;
    icon?: ReactElement;
    scrollToTop?: boolean;
    excludeFields?: string[];
    label?: string; // Voeg de label prop hier toe
}

CustomCloneButton.propTypes = {
    icon: PropTypes.element,
    label: PropTypes.string,
    record: PropTypes.any,
    excludeFields: PropTypes.arrayOf(PropTypes.string),
};

export default memo(CustomCloneButton);
