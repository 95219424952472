import { fetchUtils } from 'react-admin';
import { AuthProvider } from "react-admin";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import { dataProvider, sendPost } from './dataProvider';

const apiUrl = import.meta.env.VITE_API_URL

interface AuthData {
    access_token: string;
    refreshToken: string;
  }

interface DecodedToken {
    exp: number;
    role: string;
  }

export const fastapiAuthProvider: AuthProvider = {
    login: async ({ username, password }) => {
        try {
          const response = await axios.post(`${apiUrl}/token`, 
            new URLSearchParams({
              grant_type: 'password',
              username,
              password,
            }).toString(),
            {
              headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
            }
          );
          
          const { data } = response;
    
          if (data.access_token) {
            localStorage.setItem('auth', JSON.stringify(data));
          } else {
            return Promise.reject(new Error(data.detail));
          }
        } catch (error: any) {
            if (error.response.status == 401) {
                return Promise.reject(new Error('Username or password incorrect'));
            }
            throw new Error('Network error');
        }
      },
  logout: () => {
    localStorage.removeItem('auth');
    return Promise.resolve();
  },
  checkAuth: async () => {
    const auth = localStorage.getItem('auth');
    if (!auth) {
      return Promise.reject();
    }
    const { access_token } = JSON.parse(auth) as AuthData;
    if (isTokenExpired(access_token)) {
        localStorage.removeItem('auth');
        return Promise.reject({ redirectTo: '/login' });
    }
    return Promise.resolve();
  },
  checkError: (error) => {
    if (error.status === 401 || error.status === 403) {
      localStorage.removeItem('auth');
      return Promise.reject({ redirectTo: '/login' });
    }
    return Promise.resolve();
  },
  getIdentity: async () => {    
    const auth = localStorage.getItem('auth');
    if (!auth) {
        console.error("Token is not available");
        return Promise.reject({ redirectTo: '/login' });
      }
    const token = auth ? JSON.parse(auth).access_token : null;

    const response = await axios({
      method: 'get',
      url: apiUrl + '/me',
      headers: {
          "Authorization": `Bearer ${token}`
      }
    })
    return Promise.resolve(response.data);
  },
  getPermissions: () => {
    const auth = localStorage.getItem('auth');
    if (!auth) {
      return Promise.resolve();
    }
    const { access_token } = JSON.parse(auth) as AuthData;
  
    try {
      const decoded = jwtDecode<DecodedToken & { role: string }>(access_token); 
      return Promise.resolve(decoded.role); 
    } catch (error) {
      console.error("Failed to decode JWT:", error);
      return Promise.reject();
    }
  },
};

function isTokenExpired(token: string): boolean {
    try {
      const decoded = jwtDecode<DecodedToken>(token);
      const currentTime = Date.now().valueOf() / 1000;
      return decoded.exp < currentTime;
    } catch (error) {
      console.error("Failed to decode JWT:", error);
      return true; // Als het token niet gedecodeerd kan worden, beschouw het dan als verlopen
    }
  }

