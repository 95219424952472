import { useGetIdentity,Title, useTranslate } from "react-admin";
import { Card, CardContent, CardHeader, Typography } from "@mui/material";
export const Dashboard = () => {
  const { data: identity, isLoading: identityLoading } = useGetIdentity();
  const translate = useTranslate();

  return (
    <>
      <Title title="Dashboard" />
      <Card>
          <CardHeader title="Pollie Dashboard" />
          <CardContent>
            {identityLoading ? (
                <Typography>Loading data...</Typography>
              ) : identity ? (
                <div>
                  <Typography>{translate("resources.dashboard.welcome_back")}, {identity.first_name}</Typography>
                </div>
              ) : (
                <Typography>No user data available.</Typography>
              )}
            </CardContent>
      </Card>
    </>
    )
};