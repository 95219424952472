// in src/i18nProvider.js
import polyglotI18nProvider from 'ra-i18n-polyglot';
import englishMessages from 'ra-language-english';
import dutchMessages from 'ra-language-dutch';
import { email, I18N_CHANGE_LOCALE } from 'react-admin';

export const en_US = {
    ...englishMessages,
    app: {
        auth: {
            sign_in_to_your_account: 'Sign in to your account',
            email: 'Email',
        }
    },
    resources: {
        languages: { 
            nl_NL: "Dutch (Netherlands)",
            en_US: "English (United States)",
            es_ES: "Spanish (Spain)",
            de_DE: "German (Germany)",
            en_AU: "English (Australia)",
            en_UK: "English (United Kingdom)",
            el_GR: "Greek (Greece)",
            zh_CN: "Chinese (Mandarin, simplified)",
        },        
        dashboard: {
            welcome_back: 'Welcome back',
        },
        customers: {
            name: 'Customer |||| Customers',
            fields: {
                name: 'Name',
                created_at: 'Date',
                users: 'User |||| Users',
                assistants: 'Assistant',
            }
        },
        generic: {
            save: 'Save',
            close: 'Close',
            copy: 'Copy',
            save_and_close: 'Save & Close',
            settings: 'Settings',
            locale: 'Language',
            my_profile: 'My Profile',
            profile_updated: 'Your profile has been updated',
            unavailable: 'Not available',
        },
        users: {
            name: 'User |||| Users',
            edit: 'Edit User',
            new_password: 'New Password',
            new_password_confirm: 'Confirm Password',
            fields: {
                first_name: 'First name',
                last_name: 'Last name',
                email: 'Email',
                role: 'Role'
            }, 
        },
        conversation_notes: {
            add_note: 'Add Note',
            edit_note: 'Edit Note',
            back_to_conversations: 'Back to Conversations',
            fields: {
                note: 'Note',
                content: 'Content',
                created_at: 'Date',
                created_by: 'Created by',
                is_private: 'Private',
                status: 'Status',
                assigned_to: 'Assigned to',
            },
            statuses: {
                open: 'Open',
                closed: 'Closed',
                none: 'None',
            },
        },             
        conversations: {
            recording: 'Recording',
            overview: 'Conversation Overview',
            role: 'Role',
            content: 'Content',
            tag_updated: 'Tag updated',
            add_tag: 'Add tag',
            fields: {
                summary: 'Summary',
                topic: 'Topic',
                result: 'Result',    
                outcome: 'Outcome',
                accessible_open_notes_count: 'Open notes',
                has_open_notes: 'Open notes',
            },
            results: {
                all: 'All',
                success: 'Success',
                failure: 'Failure',
                transferred: 'Transferred',
                none: 'None',
                null: 'None',
                followup: 'Follow-up',
            }, 
            outcomes: {
                all: 'All',
                none: 'None',
                completed: 'Completed',
                caller_follow_up: 'Caller Follow-Up',
                business_follow_up: 'Business Follow-Up',
                transferred: 'Transferred',
                information_provided: 'Information Provided',
                no_action: 'No Action',
            },
            'copy_url': 'Copy URL',
            'copy_messages': 'Copy Messages',
            'url_copied': 'URL to this conversation has been copied to clipboard',
            'messages_copied': 'Messages have been copied to clipboard',
        },
        "assistants": {
            "name": "Assistant |||| Assistants",
            "edit": "Edit Assistant",
            "banner_prompt_components_line1": "Our Assistant configuration has changed. New fields have been introduced to separate instructions into distinct categories, making it more structured and easier to manage.",
            "banner_prompt_components_note": "Note:",
            "banner_prompt_components_line2": "If you do not update these fields, everything will continue to work as it is. However, using these new options will simplify maintaining and updating your settings.",
            "fields": {
                "name": "Name",
                "from_id": "Caller ID",
                "prompt": "Call Handling Instructions",
                "post_call_prompt": "Post-Call Instructions",
                "post_call_prompt_help_text": "These instructions are used after the call has completed",
                "category": "Category",
                "default_language": "Greeting Language",
                "additional_languages": "Additional Supported Languages",
                "ai_model": "AI model",
                "config": "Custom configuraton",
                "timezone": "Business Timezone",
                "created_at": "Created At",
                "notification_outcomes": "Notification Triggers",
                "notification_user": "Recipient of Notifications",
                "config.notifications.include_transcript": "Include Call Transcript in Email",
                "whatsapp_image": "WhatsApp Profile Image",
                "greeting": "Greeting Message",
                "language": "Language",
                "lexicon": "Pronunciation", 
                "edit_pronunciations": "Edit Pronunciations",
                "tasks": "Tasks",
                "deployment": "Deployment",
                "caller_id": "Caller ID",
                "fallback_number": "Fallback Number",
                "customer_id": "Customer ID",
                "prompt_scenarios.transfer_policy": "Call Transfer Policy",
                "prompt_scenarios.call_policy": "Call Handling Policies & Guidelines",
                "prompt_scenarios.faq": "Frequently Asked Questions (FAQs)",
                "prompt_scenarios.context": "Context for the call",
                "prompt_scenarios.business_information": "Business Details",
                "prompt_scenarios.role": "Assistant's Role",          
                "config.stt.interruption": "Allow Interruption",
                "config.stt.backend": "Speech-to-Text Backend",
                "config.tools.formitable.config.api_key": "Formitable API Key",
                "config.tools.formitable.config.restaurant_id": "Formitable Restaurant ID",
                "config.tts.use_human_time_format": "Use Human Time Format",
            },
            "descriptions": {
                "name": "Enter the name of your business as it will be used by the assistant",
                "timezone": "Select the timezone your business operates in",
                "whatsapp_image": "Upload the image that will be used when sending WhatsApp messages",
                "greeting": "Enter the greeting message that will be used at the beginning of conversations.",
                "default_language": "Select the language in which the caller will be greeted",
                "additional_languages": "Select additional languages the user can switch to during the call. Limit this to the most common languages spoken by your customers to avoid the possibility of switching to an incorrect language",
                "lexicon": "Enter the pronunciation of words that the assistant may not pronounce correctly",
                "prompt": "Provide detailed instructions for the tasks that Pollie should perform during the call",
                "notification_outcomes": "Select the outcomes that should trigger a notification to the specified user",
                "notification_user": "Specify the user who will receive all notifications",
                "deployment": "Details about the deployment configuration",
                "caller_id": "The caller ID to be used",
                "fallback_number": "The fallback number to be used if the main number is unreachable",
                "customer_id": "The customer associated with this assistant",
                "config": "Custom configuration for the assistant",
                "config.notifications.include_transcript": "Choose whether to include the call transcript in the email notification",
                "config.stt.interruption": "Choose whether to allow the caller to interrupt the assistant while it is speaking",
                "config.stt.backend": "Select the backend to use for speech-to-text conversion",
                "config.tools.formitable.config.api_key": "Enter the API key for Formitable",
                "config.tools.formitable.config.restaurant_id": "Enter the restaurant ID for Formitable",
                "prompt_scenarios.transfer_policy": "Specify the conditions under which calls should be transferred and the destination for the transfer",
                "prompt_scenarios.call_policy": "Outline the general call handling policies and guidelines that Pollie should follow during interactions with callers",
                "prompt_scenarios.faq": "Add common questions and their answers here to assist Pollie in providing accurate information to callers",
                "prompt_scenarios.context": "Add context for the call here, e.g. date, time and caller number",
                "prompt_scenarios.business_information": "Provide key information about your business, including operating hours, address, website, and any other relevant details that Pollie should know to assist during or after calls",
                "prompt_scenarios.role": "Describe the role and purpose of the assistant",                
                "config.tts.use_human_time_format": "This setting converts numeric time formats to a natural language format. For example, instead of '14:00', it will be pronounced as 'two o'clock'.",

            },
            try: {
                send: 'Send',
                type_your_message: 'Type your message',
                update: 'Update',
                edit_message: 'Edit Message',
            },
            errors: {
                invalid_phone: 'Invalid phone number',
            },
            general: 'General',
            call_flow: 'Call Flow',
            prompt: 'Prompt',
            notifications: 'Notifications',
            tabs: {
                "business_information": "About",
                "personality": "Personality",
                "tasks": "Instructions",
                "policies_and_context": "Policies & Context",
                "notifications": "Notifications",
                "tools": "Integrations",
                "admin": "Admin Settings", 
            }
        },
        'prompt-templates': {
            name: "Prompt Template |||| Prompt Templates"
        },         
    },    
};

export const en_AU = {
    ...en_US,  
};

export const nl_NL = {
    ...dutchMessages,
    app: {
        auth: {
            sign_in_to_your_account: 'Log in bij je account',
            email: 'Email',
        }
    },
    resources: {
        languages: { // Toevoegen van taal vertalingen onder de resources
            nl_NL: "Nederlands (Nederland)",
            en_US: "Engels (Verenigde Staten)",
            es_ES: "Spaans (Spanje)",
            de_DE: "Duits (Duitsland)",
            en_AU: "Engels (Australië)",
            en_UK: "Engels (Verenigd Koninkrijk)",
            el_GR: "Grieks (Griekenland)",
            zh_CN: "Chinees (Mandarijn, vereenvoudigd)",
        },
        dashboard: {
            welcome_back: 'Welkom terug',
        },
        generic: {
            settings: 'Instellingen',  
            locale: 'Taal',
            my_profile: 'Mijn profiel',
            profile_updated: 'Uw profiel is bijgewerkt',
            unavailable: 'Niet beschikbaar',
            save: 'Opslaan',
            save_and_close: 'Opslaan & sluiten',
            close: 'Sluiten',
            copy: 'Kopiëren',
        },
        users: {
            name: 'Gebruiker |||| Gebruikers',
            edit: 'Bewerk Gebuiker',
            new_password: 'Nieuw wachtwoord',
            new_password_confirm: 'Bevestig wachtwoord',
            fields: {
                first_name: 'Voornaam',
                last_name: 'Achternaam',
                locale: 'Taal',
                email: 'E-mail',
                role: 'Rol',
                customer_id: 'Klant',
            },
        },
        customers: {
            name: 'Klant |||| Klanten',
            fields: {
                name: 'Naam',
                created_at: 'Datum',
                users: 'Gebruiker |||| Gebruikers',
                assistants: 'Assistenten',
            }
        },
        "assistants": {
            "name": "Assistent |||| Assistenten",
            "edit": "Assistent bewerken",
            "banner_prompt_components_line1": "Onze assistentconfiguratie is gewijzigd. Er zijn nieuwe velden geïntroduceerd om instructies in afzonderlijke categorieën te scheiden, waardoor het meer gestructureerd en gemakkelijker te beheren is.",
            "banner_prompt_components_note": "Opmerking:",
            "banner_prompt_components_line2": "Als u deze velden niet bijwerkt, blijft alles werken zoals het is. Het gebruik van deze nieuwe opties zal echter het onderhouden en bijwerken van uw instellingen vereenvoudigen.",
            "fields": {
                "name": "Bedrijfsnaam",
                "customer_id": "Klant ID",
                "category": "Categorie",
                "default_language": "Begroetingstaal",
                "additional_languages": "Extra ondersteunde talen",
                "lexicon": "Uitspraak",
                "edit_pronunciations": "Uitspraak bewerken",
                "ai_model": "AI model",
                "from_id": "Beller ID",
                "prompt": "Instructies voor gespreksafhandeling",
                "post_call_prompt": "Instructies na het gesprek",
                "post_call_prompt_help_text": "Deze instructies worden gebruikt nadat het gesprek is afgerond",
                "notification_user": "Ontvanger van meldingen",
                "email": "E-mail",
                "greeting": "Begroetingsbericht",
                "formitable_id": "Formitable ID",
                "config": "Aangepaste configuratie",
                "timezone": "Bedrijfstijdzone",
                "conversations": "Gesprekken",
                "created_at": "Datum",
                "notification_outcomes": "Meldingsactiveringen",
                "config.notifications.include_transcript": "Gespreksverslag in e-mail opnemen",
                "whatsapp_image": "WhatsApp-profielafbeelding",
                "tasks": "Taken",
                "post_call_tasks": "Taken voor na het gesprek",
                "deployment": "Deployment",
                "caller_id": "Beller ID",
                "fallback_number": "Fallback Nummer",
                "prompt_scenarios.transfer_policy": "Beleid voor doorverbinden",
                "prompt_scenarios.call_policy": "Beleid en richtlijnen voor gespreksafhandeling",
                "prompt_scenarios.faq": "Veelgestelde vragen (FAQs)",
                "prompt_scenarios.context": "Context voor gesprek",
                "prompt_scenarios.business_information": "Bedrijfsgegevens",
                "prompt_scenarios.role": "Rol van de assistent",            
                "config.stt.interruption": "Onderbreking toestaan",
                "config.stt.backend": "Speech-to-Text Backend",
                "config.tools.formitable.config.api_key": "Formitable API Key",
                "config.tools.formitable.config.restaurant_id": "Formitable Restaurant ID",    
                "config.tts.use_human_time_format": "Gebruik menselijke tijdnotatie",
            },
            "descriptions": {
                "name": "Voer de naam van uw bedrijf in zoals deze door de assistent wordt gebruikt",
                "timezone": "Selecteer de tijdzone waarin uw bedrijf zich bevindt",
                "whatsapp_image": "Upload de afbeelding die wordt gebruikt bij het verzenden van WhatsApp-berichten",
                "greeting": "Voer het begroetingsbericht in dat aan het begin van gesprekken wordt gebruikt",
                "default_language": "Selecteer de taal waarin de beller wordt begroet",
                "additional_languages": "Selecteer extra talen die de gebruiker tijdens het gesprek kan kiezen. Beperk dit tot de meest gesproken talen door uw klanten om te voorkomen dat de gebruiker naar een verkeerde taal overschakelt",
                "lexicon": "Voer de uitspraak van woorden in die de assistent mogelijk niet correct uitspreekt",
                "prompt": "Geef gedetailleerde instructies voor de taken die Pollie tijdens het gesprek moet uitvoeren",
                "notification_outcomes": "Selecteer de uitkomsten die een melding naar de opgegeven gebruiker moeten activeren",
                "notification_user": "Specificeer de gebruiker die alle meldingen ontvangt",
                "deployment": "Details over de deployment configuratie",
                "caller_id": "Het te gebruiken beller-ID",
                "fallback_number": "Het fallback-nummer dat moet worden gebruikt als het hoofdnummer onbereikbaar is",
                "customer_id": "De klant die aan deze assistent is gekoppeld",
                "config": "Aangepaste configuratie voor de assistent",
                "config.notifications.include_transcript": "Kies of u het gespreksverslag in de e-mailmelding wilt opnemen",
                "prompt_scenarios.transfer_policy": "Specificeer de voorwaarden waaronder gesprekken moeten worden doorgeschakeld en de bestemming voor de doorgeschakelde oproep",
                "prompt_scenarios.call_policy": "Geef het algemene beleid voor gespreksafhandeling en de richtlijnen die Pollie moet volgen tijdens interacties met bellers",
                "prompt_scenarios.faq": "Voeg hier veelgestelde vragen en hun antwoorden toe om Pollie te helpen nauwkeurige informatie aan bellers te verstrekken",
                "prompt_scenarios.context": "Voeg hier de context voor het gesprek toe, zoals datum, tijd en beller nummer",
                "prompt_scenarios.business_information": "Geef belangrijke informatie over uw bedrijf, zoals openingstijden, adres, website en andere relevante details die Pollie moet weten om tijdens of na gesprekken te helpen",
                "prompt_scenarios.role": "Beschrijf de rol en het doel van de assistent",                
                "config.stt.interruption": "Kies of de beller de assistent mag onderbreken terwijl deze spreekt",
                "config.stt.backend": "Selecteer de backend die moet worden gebruikt voor spraak-naar-tekstconversie",
                "config.tools.formitable.config.api_key": "Voer de API-sleutel in voor Formitable",
                "config.tools.formitable.config.restaurant_id": "Voer het restaurant-ID in voor Formitable",                
                "config.tts.use_human_time_format": "Deze instelling schakelt de conversie van numerieke tijdsnotaties naar een natuurlijke taalformaat in. Bijvoorbeeld, in plaats van '14:00' wordt 'twee uur' uitgesproken.",
            },
            tabs: {
                "business_information": "Over",
                "personality": "Persoonlijkheid",
                "tasks": "Instructies",
                "policies_and_context": "Beleid & Context",
                "notifications": "Notificaties",
                "tools": "Integraties",
                "admin": "Admin Instellingen", 
            },
            try: {
                send: 'Stuur',
                type_your_message: 'Typ uw bericht',
                update: 'Bijwerken',
                edit_message: 'Bericht bewerken',
            },
            general: 'Algemeen',
            call_flow: 'Gespreksstroom',
            prompt: 'Instructies',
            notifications: 'Notificaties',
            errors: {
                invalid_phone: 'Ongeldig telefoonnummer',
            }
        },
        conversation_notes: {
            add_note: 'Notitie toevoegen',
            edit_note: 'Notitie bewerken',        
            back_to_conversations: 'Terug naar Gesprekken',
            fields: {
                note: 'Notitie',
                content: 'Inhoud',
                created_at: 'Datum',
                created_by: 'Gemaakt door',
                is_private: 'Privé',
                status: 'Status',
                assigned_to: 'Toegewezen aan',
            },
            statuses: {
                open: 'Open',
                closed: 'Gesloten',
                none: 'Geen',
            },
        },              
        conversations: {
            name: 'Gesprek |||| Gesprekken',
            overview: 'Gesprek Overzicht',
            recording: 'Opname',
            role: 'Rol',
            content: 'Inhoud',
            tag_updated: 'Tag bijgewerkt',
            add_tag: 'Tag toevoegen',
            fields: {
                assistant_id: 'Assistent',
                created_at: 'Datum',
                from_id: 'Van',
                from_name: 'Naam',
                destination: 'Bestemming',
                duration: 'Duur',
                summary: 'Samenvatting',
                topic: 'Onderwerp',
                result: 'Resultaat',
                outcome: 'Resultaat',
                messages: 'Berichten',
                accessible_open_notes_count: 'Open notities',
                has_open_notes: 'Open notities',
            },
            results: {
                all: 'Alle',
                success: 'Succesvol',
                failure: 'Fout',
                transferred: 'Doorgeschakeld',
                none: 'Geen',
                followup: 'Opvolging',
                null: 'Geen',
            },            
            outcomes: {
                all: 'Alle',
                none: 'Geen',
                completed: 'Afgerond',
                caller_follow_up: 'Opvolging Beller',
                business_follow_up: 'Opvolging Bedrijf',
                transferred: 'Doorverbonden',
                information_provided: 'Informatie Verstrekt',
                no_action: 'Geen Actie',
            },
            'copy_url': 'URL kopiëren',
            'copy_messages': 'Berichten kopiëren',            
            'url_copied': 'URL naar dit gesprek is gekopieerd naar het klembord',
            'messages_copied': 'Berichten zijn gekopieerd naar het klembord',
        },
        'prompt-templates': {
            name: "Prompt Template |||| Prompt Templates"
        }
    },
};

const LOCALE_READABLE_NAMES = {
    "en-US": "English",
    "en-AU": "English (AU)",
    "nl-NL": "Nederlands",
};

export const getReadableLocaleName = (localeCode) => {
    const normalizedLocaleCode = localeCode.replace('_', '-');
    return LOCALE_READABLE_NAMES[normalizedLocaleCode] || "Unknown Locale";
};

const translations = { en_US, en_AU, nl_NL };

// Genereer de locale opties op basis van de keys in je translations object
const localeOptions = Object.keys(translations).map(locale => ({
    locale: locale,
    name: getReadableLocaleName(locale)
}));

export const i18nProvider = polyglotI18nProvider(
    locale => translations[locale],
    'en_US', // default locale
    localeOptions,
);
