import React, { useMemo } from 'react';
import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';
import Inbox from '@mui/icons-material/Inbox';
import { useTranslate, useResourceDefinition, useResourceContext, useGetResourceLabel } from 'ra-core';
import { useParams } from 'react-router-dom';
import { CreateButton } from 'react-admin';

const PREFIX = 'RaEmpty';
export const EmptyClasses = {
    message: `${PREFIX}-message`,
    icon: `${PREFIX}-icon`,
    toolbar: `${PREFIX}-toolbar`,
};

const Root = styled('div')(({ theme }) => ({
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [`& .${EmptyClasses.message}`]: {
        textAlign: 'center',
        opacity: theme.palette.mode === 'light' ? 0.5 : 0.8,
        margin: '0 1em',
        color: theme.palette.mode === 'light' ? 'inherit' : theme.palette.text.primary,
    },
    [`& .${EmptyClasses.icon}`]: {
        width: '9em',
        height: '9em',
        marginBottom: theme.spacing(2),
    },
    [`& .${EmptyClasses.toolbar}`]: {
        marginTop: theme.spacing(2),
    },
}));

const EmptyUser = ({ className }) => {
    const resource = useResourceContext();
    const translate = useTranslate();
    const getResourceLabel = useGetResourceLabel();
    const { customerId, partnerId } = useParams();

    const createPath = useMemo(() => {
        if (customerId) return `/customers/${customerId}/users/create`;
        if (partnerId) return `/partners/${partnerId}/users/create`;
        return '/users/create';
    }, [customerId, partnerId]);

    const resourceName = useMemo(() => 
        translate(`resources.${resource}.forcedCaseName`, {
            smart_count: 0,
            _: getResourceLabel(resource, 0),
        }),
    [translate, resource, getResourceLabel]);

    const emptyMessage = useMemo(() => 
        translate('ra.page.empty', { name: resourceName }),
    [translate, resourceName]);

    const inviteMessage = useMemo(() => 
        translate('ra.page.invite'),
    [translate]);

    return (
        <Root className={className}>
            <div className={EmptyClasses.message}>
                <Inbox className={EmptyClasses.icon} />
                <Typography variant="h4" paragraph>
                    {translate(`resources.${resource}.empty`, { _: emptyMessage })}
                </Typography>
                    <Typography variant="body1">
                        {translate(`resources.${resource}.invite`, { _: inviteMessage })}
                    </Typography>
            </div>
                <div className={EmptyClasses.toolbar}>
                    <CreateButton variant="contained" to={createPath} />
                </div>
        </Root>
    );
};

export default React.memo(EmptyUser);