import { MenuItemLink, useTranslate, TopToolbar, ListButton, Create, Edit, DeleteButton, SimpleForm, Datagrid, List, TextField, EditButton, TextInput, Title } from 'react-admin';
import { Button } from '@mui/material';
import UserIcon from "@mui/icons-material/Group";
import { useRecordContext } from 'react-admin';
import { Link } from 'react-router-dom';

const LinkToRelatedUsers = () => {
    const record = useRecordContext();
    const translate = useTranslate();
    
    // Handler om de event propagatie te stoppen
    const handleClick = (event: any) => {
        // Voorkomt dat de event verder bubbelt
        event.stopPropagation();
    };
    return record ? (
        <Button
            size="small"
            onClick={handleClick}
            color="primary"
            variant="outlined"
            component={Link}
            startIcon={<UserIcon />}
            to={{
                pathname: `/partners/${record.id}/users`,
            }}
        >
            {translate('resources.users.name', { smart_count: 2 })}
        </Button>
    ) : null;
};

const postFilters = [
    <TextInput source="name" alwaysOn />,
];

const PartnerListButton = () => (
    <TopToolbar>    
        <ListButton />
    </TopToolbar>
);


export const PartnerList = () => (
    <List filters={postFilters} exporter={false} >
        <Datagrid rowClick="show" bulkActionButtons={false}>
            <TextField source="id" />
            <TextField source="name" />
            <LinkToRelatedUsers />
        </Datagrid>
    </List>
    );

export const PartnerEdit = () => (
    <Edit actions={<PartnerListButton />}>
        <SimpleForm>
            <TextInput source="name" />
        </SimpleForm>
    </Edit>
);

export const PartnerCreate = () => (
    <Create actions={<PartnerListButton />} redirect="list">
        <SimpleForm>
            <TextField source="id"  />
            <TextInput source="name" />
        </SimpleForm>

    </Create>
);


