import * as React from 'react';
import { MouseEvent, ReactNode, useState } from 'react';
import { useLocaleState, useLocales, useGetIdentity } from 'ra-core';
import { Box, Button, Menu, MenuItem, styled } from '@mui/material';
import LanguageIcon from '@mui/icons-material/Translate';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useDataProvider } from 'react-admin';
/**
 * Language selector. Changes the locale in the app and persists it in
 * preferences so that the app opens with the right locale in the future.
 *
 * Uses i18nProvider.getLocales() to get the list of available locales.
 *
 * @example
 * import { AppBar, TitlePortal, LocalesMenuButton } from 'react-admin';
 *
 * const MyAppBar = () => (
 *     <AppBar>
 *         <TitlePortal />
 *         <LocalesMenuButton />
 *     </AppBar>
 * );
 */

export const CustomLocalesMenuButton = (props: CustomLocalesMenuButtonProps) => {
    const dataProvider = useDataProvider();

    const { icon = DefaultIcon, languages: languagesProp } = props;
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const languages = useLocales({ locales: languagesProp });
    const [locale, setLocale] = useLocaleState();
    const { data: userData } = useGetIdentity(); // Haal de gebruikersdata op

    const updateBackendLocale = async (userId: any, newLocale: any) => {
        dataProvider.custom('me/change-locale', {
            method: 'POST',
            data: { locale: newLocale },
        })
        // Functie om de nieuwe taal naar de backend te sturen
        // (bijv. via een API-aanroep)
    };

    const getNameForLocale = (locale: string): string => {
        const language = languages.find(language => language.locale === locale);
        return language ? language.name : '';
    };

    const changeLocale = (newLocale: string) => async () => {
        if (userData && userData.id) {
            try {
                await updateBackendLocale(userData.id, newLocale);
                setLocale(newLocale);
            } catch (error) {
                console.error("Fout bij het bijwerken van de taal in de backend", error);
            }
        } else {
            // Behandel het geval waarin userData of userData.id niet beschikbaar is
            console.error("Fout bij het bijwerken van de taal in de backend");
        }
        setAnchorEl(null);
    };
    

    const handleLanguageClick = (event: MouseEvent<HTMLElement>): void => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (): void => {
        setAnchorEl(null);
    };

    return (
        <MenuItem>
            <Button
                color="inherit"
                variant="text"
                aria-controls="simple-menu"
                aria-label=""
                aria-haspopup="true"
                onClick={handleLanguageClick}
                startIcon={icon}
                endIcon={<ExpandMoreIcon fontSize="small" />}
            >
                {getNameForLocale(locale)}
            </Button>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                {languages.map(language => (
                    <MenuItem
                        key={language.locale}
                        onClick={changeLocale(language.locale)}
                        selected={language.locale === locale}
                    >
                        {language.name}
                    </MenuItem>
                ))}
            </Menu>
        </MenuItem>
    );
};

const DefaultIcon = <LanguageIcon />;
const PREFIX = 'RaLocalesMenuButton';

export const LocalesMenuButtonClasses = {};

const Root = styled(Box, {
    name: PREFIX,
    overridesResolver: (props, styles) => styles.root,
})({});

export interface CustomLocalesMenuButtonProps {
    icon?: ReactNode;
    languages?: { locale: string; name: string }[];
}
