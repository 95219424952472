// in src/App.tsx
import { useEffect } from 'react';
import {
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
} from "react-router-dom";

import * as Sentry from "@sentry/react";
Sentry.init({
  dsn: "https://ce312745c4769b33cf3a35e48ed07b92@o4506200448040960.ingest.us.sentry.io/4506200466391041",
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  tracesSampleRate: 1.0,
  replaysOnErrorSampleRate: 1.0,
  replaysSessionSampleRate: 0,
  enabled: import.meta.env.VITE_NODE_ENV !== "development",
});

import { Admin, Resource, CustomRoutes, useGetIdentity } from "react-admin";
import { Route } from 'react-router-dom';
import { dataProvider } from './dataProvider';
import { AssistantList, AssistantCreate, AssistantEdit } from "./assistants";
import { ConversationList, ConversationShow } from "./conversations";
import { CustomerList, CustomerEdit, CustomerCreate } from "./customers";
import { PartnerList, PartnerEdit, PartnerCreate } from "./partners";
import { UserList, UserEdit, UserCreate } from "./users";
import { PromptTemplateList, PromptTemplateEdit, PromptTemplateCreate } from "./PromptTemplates";
import ChatIcon from '@mui/icons-material/Chat';
import BusinessIcon from '@mui/icons-material/Business';
import AssistantIcon from '@mui/icons-material/Assistant';
import SubjectIcon from '@mui/icons-material/Subject';
import HandshakeIcon from '@mui/icons-material/Handshake';
import { i18nProvider } from './i18nProvider';


import { Dashboard } from './Dashboard';
import { Layout, Login } from './layout';
import { fastapiAuthProvider } from './fastapiAuthProvider'
import TryAssistant from "./TryAssistant";
import { Profile } from './Profile';

import { myTheme } from "./theme";
import { ConversationNotesList } from './ConversationNotes';

export const App = () => {
 
  return (
    <Admin 
      i18nProvider={i18nProvider}
      layout={Layout}
      loginPage={Login} 
      authProvider={fastapiAuthProvider} 
      dataProvider={dataProvider} 
      dashboard={Dashboard} 
      theme={myTheme}
      disableTelemetry={true}
    >
      {permissions => (
        <>
          {(['admin'].includes(permissions))
          ? <Resource 
              name="partners" 
              list={PartnerList} 
              edit={PartnerEdit} 
              create={PartnerCreate} 
              icon={HandshakeIcon}>
                <Route path=":partnerId/users" element={<UserList />} />
                <Route path=":partnerId/users/:id" element={<UserEdit />} />
                <Route path=":partnerId/users/create" element={<UserCreate />} />
              </Resource>
            
          : null
        }        
        {(['admin', 'partner'].includes(permissions))
          ? <Resource 
              name="customers" 
              list={CustomerList} 
              edit={CustomerEdit} 
              create={CustomerCreate} 
              icon={BusinessIcon}>
                  <Route path=":customerId/users" element={<UserList />} />
                  <Route path=":customerId/users/:id" element={<UserEdit />} />
                  <Route path=":customerId/users/create" element={<UserCreate />} />
                  {/* <Route path=":customerId/assistants" element={<AssistantList />} />
                  <Route path=":customerId/assistants/:id" element={<AssistantEdit />} />
                  <Route path=":customerId/assistants/:id/*" element={<AssistantEdit />} /> */}
              </Resource>
            
          : null
        }
        {/* {(['admin', 'user', 'partner'].includes(permissions))
          ? <Resource 
              name="users" 
              list={UserList} 
              edit={UserEdit} 
              create={UserCreate} 
              icon={UserIcon} 
            />
          : null
        }    */}
        <Resource 
          name="assistants" 
          list={AssistantList} 
          edit={AssistantEdit} 
          create={['admin', 'partner'].includes(permissions) ? AssistantCreate : undefined} 
          icon={AssistantIcon} 
        >
         <Route path=":id/try" element={<TryAssistant />} /> 
         <Route path=":id/try/from/:phoneNumber" element={<TryAssistant />} />
        </Resource>

          <Resource 
            name="conversations" 
            list={ConversationList} 
            show={ConversationShow} 
            icon={ChatIcon} 
          >
              <Route path="notes" element={<ConversationNotesList />} />
            </Resource>          
        {permissions === 'admin'
          ? <Resource 
              name="prompt-templates"              
              list={PromptTemplateList} 
              edit={PromptTemplateEdit} 
              create={PromptTemplateCreate} 
              icon={SubjectIcon}>
              </Resource>
            
          : null
        }        
        <CustomRoutes>
            <Route path="/users" element={<UserList />} />
            <Route path="/users/:id" element={<UserEdit />} />
            <Route path="/users/create" element={<UserCreate />} />
            <Route path="/profile" element={<Profile />} />
        </CustomRoutes>
        </>
      )}
    </Admin>
  );
    
}