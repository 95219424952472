import * as React from 'react';
import { useState } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import LogoAlt  from './LogoAlt';

import {
    Avatar,
    Button,
    Card,
    CardActions,
    CircularProgress,
    Typography,
    InputLabel,
} from '@mui/material';
import {
    Form,
    TextInput,
    useTranslate,
    useLogin,
    useNotify,
    email
} from 'react-admin';

import Box from '@mui/material/Box';

interface FormValues {
    username?: string;
    password?: string;
}

const Login = () => {
    const [loading, setLoading] = useState(false);
    const [formValues, setFormValues] = useState<FormValues>({});
    const translate = useTranslate();

    const requiredNoAsterisk = (message = translate('ra.validation.required')) => (value) => 
        value ? undefined : message;
    
    const notify = useNotify();
    const login = useLogin();
    const location = useLocation();

    const handleSubmit = (auth: FormValues) => {
        setLoading(true);
        setFormValues(auth);
        login(
            auth,
            location.state ? (location.state as any).nextPathname : '/'
        ).catch((error: Error) => {
            setLoading(false);
            notify(
                typeof error === 'string'
                    ? error
                    : typeof error === 'undefined' || !error.message
                    ? 'ra.auth.sign_in_error'
                    : error.message,
                {
                    type: 'error',
                    messageArgs: {
                        _:
                            typeof error === 'string'
                                ? error
                                : error && error.message
                                ? error.message
                                : undefined,
                    },
                }
            );
        });
    };

    return (
        <Form onSubmit={handleSubmit} noValidate>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    minHeight: '100vh',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: '#F7F7F2',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        transform: 'translateY(-15%)',
                    }}
                >
                    <Box
                        sx={{
                            marginBottom: '2em',
                            transform: 'scale(0.75)',
                        }}
                    >
                        <LogoAlt />
                    </Box>
                    <Card sx={{ minWidth: 400, padding: '3em' }}>
                        <Typography
                            variant="h6"
                            sx={{
                                marginBottom: '1em',
                                fontWeight: 'bold',
                                textAlign: 'center',
                                color: '#172121',
                                fontSize: '1.1rem', 
                            }}
                        >
                            {translate('app.auth.sign_in_to_your_account')}
                        </Typography>
                        <Box sx={{ marginBottom: '-0.5em' }}>
                            <InputLabel
                                htmlFor="username"
                                sx={{
                                    marginBottom: '0em',
                                    color: '#172121',
                                    fontWeight: 500,
                                    fontSize: '0.9rem', 
                                }}
                            >
                                {translate('app.auth.email')}
                            </InputLabel>
                            <TextInput
                                autoFocus
                                source="username"
                                autoComplete='username'
                                disabled={loading}
                                validate={[requiredNoAsterisk(),email()]}
                                fullWidth
                                label={null}
                                defaultValue={formValues.username}
                            />
                        </Box>
                        <Box sx={{ marginBottom: 'em' }}>
                            <InputLabel
                                htmlFor="password"
                                sx={{
                                    marginBottom: '0em',
                                    color: '#172121',
                                    fontWeight: 500,
                                    fontSize: '0.9rem',
                                }}
                            >
                                {translate('ra.auth.password')}
                            </InputLabel>
                            <TextInput
                                source="password"
                                type="password"
                                disabled={loading}
                                validate={requiredNoAsterisk()}
                                autoComplete='current-password'
                                fullWidth
                                label={null}
                                defaultValue={formValues.password}
                            />
                        </Box>
                        <Box>
                            <Button
                                variant="contained"
                                type="submit"
                                color="primary"
                                disabled={loading}
                                fullWidth
                            >
                                {loading && (
                                    <CircularProgress size={25} thickness={2} />
                                )}
                                {translate('ra.auth.sign_in')}
                            </Button>
                        </Box>
                    </Card>
                </Box>
            </Box>
        </Form>
    );
};

Login.propTypes = {
    authProvider: PropTypes.func,
    previousRoute: PropTypes.string,
};

export default Login;