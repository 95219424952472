import { defaultTheme } from 'react-admin';

export const myTheme = {
    ...defaultTheme,
    components: {
        ...defaultTheme.components,
        MuiTextField: {
            defaultProps: {
                variant: 'outlined',
            },
            styleOverrides: {
                root: {
                    '&.custom-multiline .MuiInputBase-inputMultiline': {
                        minHeight: '50px', // Deze stijl zal alleen toegepast worden op textareas met de 'custom-min-height' klasse
                        fontSize: '1rem',
                    }                 
                }
            }            
        },
        // MuiFormControl: {
        //     defaultProps: {
        //         variant: 'outlined',
        //     },
        // },
        MuiButton: {
            // defaultProps: {
            //     variant: 'contained',
            //     color: 'primary',
            // },
            styleOverrides: {
                root: {
                    boxShadow: 'none', // remove app bar shadow
                    textTransform: 'capitalize',
                },
            //     contained: {
            //         color: '#ffffff', 
            //         border: '1px solid rgba(252, 128, 119, 0.7)',
            //         backgroundColor: 'rgba(252, 128, 119, 0.7)', 
            //         '&:hover': {
            //             color: '#ffffff', 
            //             backgroundColor: 'rgba(252, 128, 119, 1)', 
            //             boxShadow: 'none', // remove app bar shadow                           
            //         },

            //     },
            //     outlined: {
            //         color: 'rgba(23, 33, 33, 0.7)', 
            //         borderColor: 'rgba(23, 33, 33, 0.5)', 
            //     }
            }

        },
        RaSidebar: {
            styleOverrides: {
                docked: {
                    display: 'none'
                }
            }
        },
        // override default menu item state
        RaMenuItemLink: {
            styleOverrides: {
                root: {
                    color: '#172121', // Donkerblauwe kleur voor alle buttons
                }
            }
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {        
                    // Standaard stijlen voor MuiMenuItem
                    '&:hover': {
                        color: 'rgba(252, 128, 119, 0.8)', // Roze kleur met lichte opacity voor hover
                        backgroundColor: 'transparent !important', // Geen achtergrondkleur bij hover                            
                    },
                    // Specifieke stijlen voor geselecteerde menu-items
                    '&.RaMenuItemLink-active': {
                        color: '#FC8077 !important', // Roze kleur voor actieve items
                        backgroundColor: 'rgba(252, 128, 119, 0.10)',
                        fontWeight: 'bold',
                        '&:hover': {
                            color: 'rgba(252, 128, 119, 1) !important', // Volle roze kleur voor hover op actieve items
                        },
                    },
                },
            },
                        
        },
        MuiDrawer: {
            styleOverrides: {
                docked: {
                    borderRight: '1px solid rgba(0, 0, 0, 0.12)', // Voeg een rechterrand toe
                    marginRight: '10px',
                },
            },
        },        
        // remove appbar shadow
        MuiAppBar: {
            styleOverrides: {
                root: {
                    boxShadow: 'none', 
                    borderBottom: '1px solid rgba(0, 0, 0, 0.12)', 
                },
            },
        },
        // remove paper shadow and table borders
        MuiPaper: {
            styleOverrides: {
                root: {
                    border: 'none', // Verwijder de linker rand
                    boxShadow: 'none', // remove app ba
                },
            },
        },
        // column headers in bold
        MuiTableCell: {
            styleOverrides: {
                head: { // 
                    fontWeight: 'bold',
                    borderBottom: '2px solid rgba(0, 0, 0, 0.22)', // Voeg een 2px dikke onderste rand toe
                },
            },
        },            
    },
    sidebar: {
        width: 210, 
        closedWidth: 55, 
    },
    palette: {
        primary: {
            main: '#368F8B', 
        },
        secondary: {
            main: '#ffffff',
            contrastText: '#141c1c', 
        },
        text: {
            primary: '#172121', 
        },                
    },

    typography: {
        // Use the system font instead of the default Roboto font.
        fontFamily: ['-apple-system', 'Figtree', '"Segoe UI"', 'Arial', 'sans-serif'].join(','),
    },
};